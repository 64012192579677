import React from 'react';

function Bell(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.6" height="16.958" viewBox="0 0 13.6 16.958">
            <path id="bell" d="M15.9,11.5V8.8a5.1,5.1,0,0,0-4.25-5.023V2.85a.85.85,0,1,0-1.7,0v.926A5.1,5.1,0,0,0,5.7,8.8v2.7A2.55,2.55,0,0,0,4,13.9v1.7a.85.85,0,0,0,.85.85H7.519a3.4,3.4,0,0,0,6.562,0H16.75a.85.85,0,0,0,.85-.85V13.9a2.55,2.55,0,0,0-1.7-2.4ZM7.4,8.8a3.4,3.4,0,1,1,6.8,0v2.55H7.4Zm3.4,8.5a1.7,1.7,0,0,1-1.462-.85h2.924A1.7,1.7,0,0,1,10.8,17.3Zm5.1-2.55H5.7V13.9a.85.85,0,0,1,.85-.85h8.5a.85.85,0,0,1,.85.85Z" transform="translate(-4 -2)" fill="#1a242d"/>
        </svg>

    );
}

export default Bell;
