import React, {Component} from 'react';
import Event from "../modules/svg/event";
import Bell from "../modules/svg/bell";
import Envelope from "../modules/svg/envelope";
import Message from "../modules/svg/message";
import RoundedImage from "../modules/roundedImage";

class AdminHeader extends Component {
    render() {
        return (
            <div className="admin-header-section">
                <div className="logo-expand d-flex">
                    <button><i className="fa fa-bars"></i></button>
                    <div>
                        <img style={{width: '150px'}} src="https://silverbird.s3.amazonaws.com/234567890/images/meta/logo/salon-h2o.jpg" alt="logo"/>
                    </div>
                </div>
                <div className="header-menus">
                    <ul>
                        <li><a href="#"><Event/></a></li>
                        <li><a href="#"><Bell/></a></li>
                        <li><a href="#"><Envelope/></a></li>
                        <li><a href="#"><Message/></a></li>
                    </ul>

                    <div className="authenticated-user">
                        <a href="#">
                            <RoundedImage image="https://silverbird.s3.amazonaws.com/472822496/images/meta/team/Shay-051019.jpg"/>
                            <span>Seyi Badmus</span>
                            <i className="fa fa-angle-down"></i>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}

export default AdminHeader;

