import React from 'react';

function Event(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.6" height="17" viewBox="0 0 13.6 17">
            <path id="clipboard-notes"
                  d="M11.65,12.2H8.25a.85.85,0,0,0,0,1.7h3.4a.85.85,0,1,0,0-1.7Zm3.4-8.5h-1A2.55,2.55,0,0,0,11.65,2H9.95a2.55,2.55,0,0,0-2.4,1.7h-1A2.55,2.55,0,0,0,4,6.25v10.2A2.55,2.55,0,0,0,6.55,19h8.5a2.55,2.55,0,0,0,2.55-2.55V6.25A2.55,2.55,0,0,0,15.05,3.7ZM9.1,4.55a.85.85,0,0,1,.85-.85h1.7a.85.85,0,0,1,.85.85V5.4H9.1Zm6.8,11.9a.85.85,0,0,1-.85.85H6.55a.85.85,0,0,1-.85-.85V6.25a.85.85,0,0,1,.85-.85H7.4v.85a.85.85,0,0,0,.85.85h5.1a.85.85,0,0,0,.85-.85V5.4h.85a.85.85,0,0,1,.85.85ZM13.35,8.8H8.25a.85.85,0,1,0,0,1.7h5.1a.85.85,0,0,0,0-1.7Z"
                  transform="translate(-4 -2)" fill="#1a242d"/>
        </svg>
    );
}

export default Event;
