import React, { Component } from 'react';
import { Link, withRouter,NavLink } from 'react-router-dom';
import {connect} from 'react-redux'
import classname from 'classnames'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';

import LogoWhite from '../../assets/img/sp-logo.png';
import bar from '../../assets/img/Hamburger-style.png'


class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      scrolling: false,
      transparene: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/') {
      this.setState({
        transparene: true
      })

    }


    // OnScolll
    const onScroll = e => {
      this.setState({
        scrolling: e.target.documentElement.scrollTop > 100
      })
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)


  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== '/') {
      this.setState({
        transparent: true
      })
    } else {
      this.setState({
        transparent: false
      })
    }
  }

  toggle() {
    window.uncollapseMenu()
  }

  render() {
    const {activeIndex} = this.props

    return (
      <Navbar id="active" style={
        //eslint-disable-next-line
        this.props.location.pathname != '/' ? {backgroundColor: '#fff'} : {backgroundColor: 'transparent'}}
              className={classname("navbar-transparent", { "fixed-top": this.state.scrolling, 'navbar-white': this.state.transparent })} expand="md">
        <Link className="logo-mobile" to="/">
          {activeIndex === 0 ? <img src={LogoWhite} alt="Logo" /> : <img src={LogoWhite} alt="Logo" />}
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse navbar>

          <Nav navbar className="navbar-nav-left">
            <NavItem>
              <NavLink className="nav-link" to="/products">Products</NavLink>
            </NavItem>

          </Nav>


          <Link className="logo-desktop" to="/">
            {activeIndex === 0 ? <img src={LogoWhite} alt="Logo" /> : <img src={LogoWhite} alt="Logo" />}
          </Link>

          <Nav navbar className="navbar-nav-right">
            {
              //eslint-disable-next-line
              this.props.location.pathname == '/' ?
                <NavItem>
                  <NavLink className="nav-link" to="/login">SILVERBIRD  <i style={{textTransform: 'lowercase',marginRight: '3px'}}> for </i>  BUSINESS</NavLink>
                </NavItem>
                : <div class="bar"> <img src={bar} alt="bar"/> <div className="rotate-menu"><p>{this.props.location.pathname.split('/')[1].toUpperCase()}</p></div> </div> }


          </Nav>

        </Collapse>
      </Navbar>
    )
  }

}

function mapStateToProps(state) {
  const {activeIndex} = state.headerReducer;
  return {activeIndex}
}



export default connect(mapStateToProps)(withRouter(Header));
