import React from 'react'
import Header from '../components/Header/Header';
import Footer from "../components/Footer/footer";
import CopyRight from "../components/Copyright/copyRight";
import {withRouter} from 'react-router-dom';
import AdminHeader from "../Admin/header/adminHeader";

const Layouts = (props) => {
    let PATH = props.history.location.pathname
    let adminPath = PATH.includes('admin')

    return (
        <>
            {adminPath == true ? <AdminHeader/> : <Header/>}
            {props.children}
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default withRouter(Layouts);
