import React from 'react'
import SwiperCore, {Pagination, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {connect} from 'react-redux'
import logo from '../../assets/img/full-logo.png'
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import bannerText from '../../assets/img/banner/banner_text.png';
import playStore from '../../assets/img/banner/playStore.png'
import appStore from '../../assets/img/banner/appStore.png'

import sliderData from './sliderData'

// install Virtual module
SwiperCore.use([Pagination, Autoplay]);

class HeroSlider extends React.Component {

    render() {
        return (
            <div className={`slider-bg-holder perfect`}
                 style={{backgroundImage: `url(${sliderData[0].img})`}}
                // style={{ background: `${e.img}` }}
            >
                <div className="banner-wholder">
                    <div className="banner-content">
                        <h3>Silverbird</h3>
                        <span>
                        AI-driven <br/>beauty app
                    </span>
                        <p>Download the Mobile App</p>
                        <div className="d-flex justify-content-center">
                            <a href="#"><img src={appStore} alt="App Store"/></a>
                            <a href="#"><img src={playStore} alt="Play Store"/></a>
                        </div>

                        <a className="explore" href="#">or Explore more products</a>
                    </div>
                </div>
            </div>

        );
    }


};

function mapStateToProps(state) {
    const {activeIndex} = state.headerReducer;
    return {activeIndex}
}

export default connect(mapStateToProps)(HeroSlider);
