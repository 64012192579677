import React, {useEffect} from 'react';
import {Switch, Route,useLocation } from 'react-router-dom';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import Dashboard from "./Admin/screens/dashboard";
import Team from './pages/Team'

// Custom component
import Layout from './layouts/Layouts';
import Home from './pages/Home';
import Salon from './pages/SalonPage';
import Technology from './pages/Technology';
import About from './pages/About';
import {connect} from 'react-redux'
import {userServices} from "./_services";
import Privacy from "./pages/Privacy";
import Login from "./pages/Login";



const App=(props) => {
  const location = useLocation();
    useEffect(() => {
       // getAllSalonStaffs()
        window.changeByLocation(location.pathname)
    },[]);


  return (

      <Layout>

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/salons" component={Salon} />
          <Route path="/products" component={Technology}/>
          <Route path="/about" component={About} />
          <Route path="/team" component={Team} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/login" component={Login} />
          <Route path="/admin/salon-h2o" component={Dashboard} />
        </Switch>
      </Layout>

  );
}

export default connect()(App);
