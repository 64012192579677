import React from 'react';
import {Link} from "react-router-dom";
const LoginForm = () => {
    return (
        <>

            <div className="form-wrapper">
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Salon ID</label>
                        <input type="email" className="form-control" id="exampleInputEmail1"
                               aria-describedby="emailHelp"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input type="email" className="form-control" id="exampleInputEmail1"
                               aria-describedby="emailHelp"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1"/>
                    </div>
                    <div className="form-group">
                        <p>Forgot your password? / <Link to="/account">Create an account.</Link></p>
                    </div>

                    <button type="submit" className="btn-custom-sign-in">Sign In</button>
                </form>
            </div>
        </>
    );
};

export default LoginForm;
