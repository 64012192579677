import React from 'react';
const defaultSize = '40px';
function RoundedImage(props) {
    return (
        <div className="rounded-image" style={{
            backgroundImage:'url('+props.image+')',
            width: props.width ? props.width : defaultSize,
            height: props.height ? props.height : defaultSize,
            borderRadius: props.width ? props.width : defaultSize / 2
        }}/>
    );
}

export default RoundedImage;
