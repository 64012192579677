import silverbird from '../../assets/img/mobiles/0.png'
import clover from '../../assets/img/mobiles/1.png'
import admin from '../../assets/img/mobiles/2.png'
import healthy_fabulous from '../../assets/img/banner/healthy_fabulous.jpg'
import look_your_best from '../../assets/img/banner/look_your_best.jpg'
import more_beautiful_looking from '../../assets/img/banner/more_beautiful_looking.jpg'
import relax_rejuvenate from '../../assets/img/banner/relax_rejuvenate.jpg'

const homeSliderData = [
  {
    id: 1,
    img: silverbird,
    title: 'MILLIONS OF MILES',
    longTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad ',
    button: 'FIND OUT HOW',
    cls: 'silverbird',
    bg: '#1B2A2F'
  }
];

export default homeSliderData;
