import React, {Component, useEffect} from 'react';
import Background from "../components/full-height-background/background";
import image from '../assets/img/login/login_background.jpg'
import LoginForm from "../components/Login/LoginForm";
import {connect} from 'react-redux'
import Height from "../components/vairableCss/height";
import {Helmet} from "react-helmet";

class Login extends Component {

    componentDidMount() {
        window.changeByLocation();
        window.collapseMenu()

    }

    render() {


        return (
            <>
                <div
                    className="main-content login">
                    <div className="container-fluid">
                        <div className="height-50"></div>
                        <div className="content d-flex flex-column flex-lg-row">
                            <Background image={image}/>
                            <div className="content-form">
                                <Height height="40px"/>
                                <div className="form-title">
                                    <h3>Welcome back!</h3>
                                    <p>Sign in to your Silverbird account.</p>
                                </div>
                                <LoginForm/>
                            </div>
                        </div>
                    </div>




                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Login - Silverbird - The salon of the future</title>
                    </Helmet>
                </div>
            </>
        );
    }
}

export default connect()(Login);
