import React from 'react';

function Envelope(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.599" height="19.3" viewBox="0 0 22.599 19.3">
            <g id="Group_682" data-name="Group 682" transform="translate(-1070.401 -18)">
                <path id="envelope" d="M16.45,4H4.55A2.55,2.55,0,0,0,2,6.55v8.5A2.55,2.55,0,0,0,4.55,17.6h11.9A2.55,2.55,0,0,0,19,15.05V6.55A2.55,2.55,0,0,0,16.45,4ZM16.1,5.7l-5,5a.85.85,0,0,1-1.207,0l-5-5Zm1.2,9.35a.85.85,0,0,1-.85.85H4.55a.85.85,0,0,1-.85-.85V6.9l5,5a2.55,2.55,0,0,0,3.6,0l5-5Z" transform="translate(1068.401 19.7)" fill="#1a242d"/>
                <circle id="Ellipse_181" data-name="Ellipse 181" cx="6" cy="6" r="6" transform="translate(1081 18)" fill="#fcbf24"/>
            </g>
        </svg>


    );
}

export default Envelope;
