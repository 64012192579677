import React, {useEffect, useState} from 'react';

import Background from "../components/full-height-background/background";
import image from "../assets/img/team/team_bg.jpg";
import Height from "../components/vairableCss/height";
import classnames from 'classnames';
import {
    Carousel,
    CarouselItem
} from 'reactstrap';
import SingleTeam from "../components/team/singleTeam";
import {techs} from '../components/Data/technicians'
import {userServices} from "../_services";
import {salons} from "../components/Data/salons";
import {connect} from 'react-redux'
import {Helmet} from "react-helmet";

const Team = (props) => {

    const staffsFromStorage = localStorage.getItem('staffs') && JSON.parse(localStorage.getItem('staffs'));

    const [staffs, setStaffs] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    useEffect(() => {
        window.changeByLocation();
        //getAllSalonStaffs()
        window.collapseMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    const next = () => {
        // if (animating) return;
        // const nextIndex = activeIndex === staffs.length - 1 ? 0 : activeIndex + 1;
        // setActiveIndex(nextIndex);
    }

    const previous = () => {
        // if (animating) return;
        // const nextIndex = activeIndex === 0 ? staffs.length - 1 : activeIndex - 1;
        // setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        // if (animating) return;
        // setActiveIndex(newIndex);
    }

    const findSalonName = (id) => {
        let salon = salons.find(sln => sln.id === id);
        if (salon) return salon.name
    }

    const slides = (item) => {
        console.log(item)
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={0}
            >
                <div className="salon-block d-flex align-items-center flex-wrap">
                    <div>01</div>
                </div>
            </CarouselItem>
        );
    };


    return (
        <div className="main-content about team">
            <div className="container-fluid">
                <div className="height-50"></div>
                <div className="content d-flex flex-column flex-lg-row">
                    <Background image={image}/>
                    <div className="content-form">
                        <Height height="40px"/>
                        <div className="form-title">
                            <h3>Team</h3>
                        </div>
                        <div className="description" style={{maxWidth: '500px'}}>
                            <p>We are a team of passionate individuals who love helping our amazing clients accentuate
                                their beauty by utilizing the best techniques, products, and technology in the industry.
                                We strive to be the best. Meet our team!</p>
                        </div>
                        <Height height="20px"/>

                        <Height height="20px"/>

                        {/*<Carousel*/}

                        {/*>*/}
                        {/*    <ol className="carousel-indicators">*/}
                        {/*        {staffs.map((item, index) => {*/}
                        {/*            return (*/}
                        {/*                <li*/}
                        {/*                    key={index}*/}
                        {/*                    onClick={() => goToIndex(index)}*/}
                        {/*                    className={classnames({active: activeIndex === index})}*/}
                        {/*                    style={{backgroundImage: `url(${item.src})`}}*/}
                        {/*                >{'0' + (index + 1)}</li>*/}
                        {/*            );*/}
                        {/*        })}*/}
                        {/*    </ol>*/}
                        {/*    <CarouselItem>*/}
                        {/*        <div className="salon-block d-flex align-items-center flex-wrap">*/}
                        {/*            <div>01</div>*/}
                        {/*        </div>*/}
                        {/*    </CarouselItem>*/}

                        {/*</Carousel>*/}
                        <Height height="50px"/>
                        <h5 className="tech">Tech</h5>
                        <Height height="20px"/>

                        <div className="salon-block d-flex flex-md-row align-xl-items-center flex-wrap">
                            {techs && techs.length > 0 && techs.map((tech, key) => {
                                return <React.Fragment key={key}><SingleTeam tech={tech} keys={key}/></React.Fragment>
                            })}
                        </div>

                    </div>
                </div>
            </div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Team - Silverbird - The salon of the future</title>
            </Helmet>
        </div>
    )
}

export default connect()(Team);
