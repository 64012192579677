import React from 'react';

function Message(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Group_683" data-name="Group 683" transform="translate(-1118 -18)">
                <path id="comment-alt-lines"
                      d="M11.35,9.65H6.25a.85.85,0,1,0,0,1.7h5.1a.85.85,0,1,0,0-1.7Zm3.4-3.4H6.25a.85.85,0,1,0,0,1.7h8.5a.85.85,0,0,0,0-1.7ZM16.45,2H4.55A2.55,2.55,0,0,0,2,4.55v8.5A2.55,2.55,0,0,0,4.55,15.6H14.4l3.145,3.153a.85.85,0,0,0,.6.246.714.714,0,0,0,.323-.068A.85.85,0,0,0,19,18.15V4.55A2.55,2.55,0,0,0,16.45,2Zm.85,14.1-1.946-1.955a.85.85,0,0,0-.6-.246H4.55a.85.85,0,0,1-.85-.85V4.55a.85.85,0,0,1,.85-.85h11.9a.85.85,0,0,1,.85.85Z"
                      transform="translate(1116 22)" fill="#1a242d"/>
                <circle id="Ellipse_175" data-name="Ellipse 175" cx="6" cy="6" r="6" transform="translate(1129 18)"
                        fill="#fcbf24"/>
            </g>
        </svg>

    );
}

export default Message;
