
import rubel from '../../assets/img/team/rubel.jpg'
import abu from '../../assets/img/team/abu.jpg'
import hasitha from '../../assets/img/team/hasitha.jpg'
import mahadi from '../../assets/img/team/mahadi.jpg'
import aliul from '../../assets/img/team/aliul.jpg'
import shashika from '../../assets/img/team/shashika.jpg'

export const techs = [

        {
            id: 1,
            name: 'Rubel',
            title: 'Lead',
            img: rubel,
            technologies: 'Web / App'
        },
        {
            id: 2,
            name: 'Abu',
            title: 'Developer',
            img: abu,
            technologies: 'Android'
        },
        {
            id: 3,
            name: 'Hasitha',
            title: 'Developer',
            img: hasitha,
            technologies:'API'
        },
        {
            id: 4,
            name: 'Dipu',
            title: 'Developer',
            img: aliul,
            technologies:'Web'
        },
        {
            id: 5,
            name: 'Shashi',
            title: 'QA',
            img: shashika,
            technologies: 'App / API'
        },
        {
            id: 6,
            name: 'Mahadi',
            title: 'Designer',
            img: mahadi,
            technologies:'UI/UX'
        }

]
